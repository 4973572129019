import * as S from "./style";
import React, { useEffect } from "react";
import { SITE_NAME } from "constant/configs";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>개인정보 처리방침</h2>
          </div>
          <S.Content className="cms-content">
            <div
              dangerouslySetInnerHTML={{
                __html: `<p style="text-align: justify;"><strong><span style="font-size:20pt;">${SITE_NAME} 개인정보 처리방침</span></strong></p>
<p><br></p>
<p class="head-line white" style="text-align: justify;"><strong><span style="font-size:10pt;">${SITE_NAME} (이하&quot;회사&quot;)은 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립&middot;공개합니다.&nbsp;</span></strong></p>
<p><br></p>
<p class="head-line white" style="text-align: justify;"><strong><span style="font-size:10pt;">개인정보의 처리 목적, 수집항목, 보유 및 이용기간</span></strong></p>
<p style="text-align: justify;"><span style="font-size:10pt;">회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.&nbsp;</span></p>
<p><br></p>
<p style="text-align: justify;"><strong><span style="font-size:10pt;">[</span></strong><strong><span style="font-size:10pt;">회사는 다음의 개인정보 항목을 정보주체의 동의 없이 처리할 수 있습니다.]</span></strong></p>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #000000 0.5pt; width: 300px;">
                    <p style="text-align: center;"><span style="font-size:10pt;">구분</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">법적근거</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">내용</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="3" style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 회원가입 및 로그인</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;"><br><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">수집목적</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 회원가입</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 서비스로그인</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 서비스 제공</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 본인확인(본인인증)</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 각종 고지 및 통지 고객지원 서비스</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #000000 0.5pt; width: 70px;"><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">수집항목</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">개인정보보호법 제15조</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">제1항 제4호</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">(계약의 이행)</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 회원가입 및 로그인</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">-성명, 휴대전화번호, 생년월일, 성별, 통신사,이메일, 은행, 계좌번호</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">보유 및 이용기간</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 회원 탈퇴 시 즉시 폐기</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 휴대폰 본인인증</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 신분증 본인확인</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 계좌확인</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;"><br></td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;"><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">수집목적</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 고객 확인 의무 및 실지 명의 확인</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 고실제 소유자에 대한 확인</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 고객 확인 절차</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #000000 0.5pt;"><br><br><br>
                    <p style="text-align: justify;"><span style="font-size:10pt;">수집항목</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">개인정보보호법 제15조</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">제1항 제2호</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">(법령상 의무 준수)</span></p><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">특정 금융거래정보의 보고 및 이용 등에 관한 법률</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">제5조의 제1항 제3호</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">(금융회사등의 고객 확인의무)</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 휴대폰 본인인증</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">-성명, 휴대전화번호, 생년월일, 성별, 통신사</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">신분증 본인확인</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">-공통(주민등록증): 성명, 생년월일, 성별, 발급일자, 주민등록번호</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">-운전면허증: 운전면허번호, 암호일련번호</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">보유 및 이용기간</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">회원 탈퇴 후 5년 보관 후 파기</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 가상자산 지갑주소 등록</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;"><br></td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;"><br><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">수집목적</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 가상자산 입&bull;출금 지갑 주소 등록</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 보고의무 준수</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 가상자산 거래 입&bull;출금 처리 확인</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 자산 반환 처리</span></p>
                </td>
            </tr>
            <tr>
                <td rowspan="2" style="border: solid #000000 0.5pt;"><br><br><br><br><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">수집항목</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">개인정보보호법 제15조</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">제1항 제2호</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">(법령상 의무 준수)</span></p><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">특정 금융거래정보의 보고 및 이용 등에 관한 법률</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">제5조3</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">(전산송금 시 정보 제공)</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 가상자산 지갑주소 등록</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">-성명, 휴대전화번호, 가상자산출금주소, 지갑주소, 신분증 사본(주민등록번호 뒷자리 마스킹)</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #000000 0.5pt;"><br>
                    <p style="text-align: center;"><span style="font-size:10pt;">개인정보보호법 제15조</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">제1항 제4호</span></p>
                    <p style="text-align: center;"><span style="font-size:10pt;">(계약의 이행)</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">&bull; 자산 반환 처리</span></p>
                    <p style="text-align: justify;"><span style="font-size:10pt;">-본인 확인: 신분증 사본(사진,성명,생년월일,성별을 제외한 정보 마스킹), 은행 계좌 통장 사본(성명, 계좌번호, 금융기관명)</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="border: solid #000000 0.5pt;">
                    <p style="text-align: center;"><span style="font-size:10pt;">보유 및 이용기간</span></p>
                </td>
                <td style="border: solid #000000 0.5pt;">
                    <p style="text-align: justify;"><span style="font-size:10pt;">회원 탈퇴 후 5년 보관 후 파기</span></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p><br></p>
<p><br></p>
<p  class="head-line white" style="text-align: justify;"><strong><span style="font-size:10pt;">&nbsp;개인정보의 파기 절차 및 방법</span></strong></p>
<p style="text-align: justify;"><span style="font-size:10pt;">① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">1. 파기절차</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">2. 파기방법</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">회사는 전자적 파일 형태로 기록&middot;저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록&middot;저장된 개인정보는 분쇄기로 분쇄 하거나 소각하여 파기합니다.</span></p>
<p><br></p>
<p  class="head-line white" style="text-align: justify;"><strong><span style="font-size:10pt;">&nbsp;개인정보 제3자 제공</span></strong></p>
<p style="text-align: justify;"><span style="font-size:10pt;">① 회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">② 회사는 계좌 이체 및 출금이체 서비스의 원활한 이용을 위해 정보주체의 동의를 얻어 해당금융기관에 필요 최소한의 범위의 개인정보를 제공합니다.</span></p>
<p><br></p>
<p  class="head-line white" style="text-align: justify;"><strong><span style="font-size:10pt;">︎ 개인정보의 안전성 확보조치에 관한 사항</span></strong></p>
<p style="text-align: justify;"><span style="font-size:10pt;">회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">① 관리적 조치</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">1.내부관리계획 수립</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">가. 회사는 개인정보의 안전한 관리를 위해 개인정보 내부관리계획을 수립하여 시행하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">2. 처리 직원의 최소화 및 교육</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">가. 회사는 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보 처리방침의 준수를 항상 강조하고 있습니다. &nbsp;</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">3. 내부관리 계획에 의한 정기적인 자체 점검을 통해 개인정보보호 정책을 준수를 확인 및 감독 활동을 합니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">&nbsp;② 기술적 조치</span></p>
<p><br></p>
<p style="text-align: justify;"><span style="font-size:10pt;">1. 개인정보 암호화</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">가. 회사는 회원의 비밀번호 및 계좌번호 등의 개인정보를 안전한 암호알고리즘에 의해 암호화되어 저장 및 관리하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">2. 해킹 등에 대비한 대책</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">가. 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 회원들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">나. 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">다. 회사는 개인정보가 포함된 출력, 복사물에 대해 안전하게 관리하기 위해 제한적으로 허용하고 있으며, 안전하게 관리하기 위한 보호조치를 마련하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">③ 물리적 조치&nbsp;</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">1. 회사는 개인정보처리시스템의 접속기록이 위∙변조되지 않도록 별도의 물리적인 저장 장치에 보관하고 있으며, 정기적인 백업을 수행하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">2. 회사는 전산실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 있는 경우에는 이에 대한 출입 통제 절차를 수립 운영하고 있습니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">3. 회사는 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.</span></p>
<p><br></p>
<p  class="head-line white" style="text-align: justify;"><strong><span style="font-size:10pt;">︎ 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항</span></strong></p>
<p style="text-align: justify;"><span style="font-size:10pt;">① 회사는 회원의 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 &lsquo;쿠키(Cookie)&rsquo;를 사용합니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">&nbsp;1. &nbsp;쿠키의 사용 목적: 회원이 방문한 ${SITE_NAME}서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부, 로그인 기능 수행 등 회원에게 최적화된 정보 제공을 위해 사용됩니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 회원의 컴퓨터 브라우저에게 보내는 소량의 정보이며, 회원들의 PC 컴퓨터내 또는 모바일에 저장되기도 합니다.</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">③ 정보주체는 웹 브라우저 옵션 설정을 통해 쿠키 허용, 차단 등의 설정을 할 수 있습니다. 다만, 구키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</span></p>
<p><br></p>
<p style="text-align: justify;"><span style="font-size:10pt;">▶ 웹 브라우저에서 쿠키 허용/차단</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">크롬(Chrome) : 웹 브라우저 설정 &gt; 개인정보 보호 및 보안 &gt; 인터넷 사용 기록 삭제</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">엣지(Edge) : 웹 브라우저 설정 &gt; 쿠키 및 사이트 권한 &gt; 쿠키 및 사이트 데이터 관리 및 삭제</span></p>
<p><br></p>
<p style="text-align: justify;"><span style="font-size:10pt;">▶ 모바일 브라우저에서 쿠키 허용/차단</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">크롬(Chrome) : 모바일 브라우저 설정 &gt; 개인정보 보호 및 보안 &gt; 인터넷 사용 기록 삭제</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">사파리(Safari) : 모바일 기기 설정 &gt; 사파리(Safari) &gt; 고급 &gt; 모든 쿠키 차단&nbsp;</span></p>
<p style="text-align: justify;"><span style="font-size:10pt;">삼성 인터넷 : 모바일 브라우저 설정 &gt; 인터넷 사용 기록 &gt; 인터넷 사용 기록 삭제</span></p>
<p><br></p>
<p><br></p>`,
              }}
            />
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
