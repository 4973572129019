import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../assets/images/logo.svg";
import * as S from "./style";
import { useAuth } from "customHook/useAuth";
import { Button, message } from "antd";
import { logout } from "features/user/userSlice";
import { useAppDispatch } from "app/hooks";
import { openLoading, closeLoading } from "features/loading/loadingSlice";

// var scroller = Scroll.scroller;
// import { Link, useLoaderData } from "react-router-dom";

function Header() {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const { width }: any = useWindowSize();
  const { isLogined } = useAuth();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  // console.log("location", location.pathname);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowMenuMobile = () => {
    if (width <= 1024) {
      setIsOpenMenu(!isOpenMenu);
    }
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const position = element.offsetTop;
      scroll.scrollTo(position, {
        spy: true,
        smooth: true,
        offset: -50,
        duration: 500,
      });
    }
  };

  const handleClickLogo = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const menuItems = [
    { path: "/terms-of-use", label: "이용약관" },
    { path: "/privacy-policy", label: "개인정보 처리방침" },
    ...(isLogined
      ? [
          { path: "/purchase", label: "간편구매" },
          { path: "/sell", label: "간편판매" },
          { path: "/mypage", label: "마이페이지" },
        ]
      : []),
    // { path: "/inquire", label: "고객지원" },
  ];

  return (
    <S.Wrapper>
      {contextHolder}
      <header
        id="header"
        className={`submenu-light ${
          width > 1024 && scrollPosition > 100 ? "header-sticky" : "dark"
        } ${width > 1024 && scrollPosition > 200 ? "sticky-active" : "dark"}`}
        style={{
          backgroundColor: `${
            location.pathname === "/" ? "#03000A" : "transparent"
          }`,
        }}
      >
        <div className="header-inner">
          <div className="container">
            {/*Logo*/}
            <div id="logo" onClick={handleClickLogo}>
              <Link to="/">
                <span className="logo-default">
                  <img alt="logo" src={logo} />
                </span>
              </Link>
            </div>
            <div id="mainMenu-trigger">
              <a onClick={handleShowMenuMobile} className="lines-button x">
                <span className="lines" />
              </a>
            </div>
            {/*end: Navigation Resposnive Trigger*/}
            {/*Navigation*/}
            {width <= 1024 && (
              <div
                className={`over-layout-menu ${isOpenMenu ? "active" : ""}`}
                onClick={() => setIsOpenMenu(false)}
              />
            )}
            <S.BoxMenu id="mainMenu" className={isOpenMenu ? "open-menu" : ""}>
              <div className="container">
                {width <= 1024 && (
                  <S.CloseMenu>
                    <a
                      onClick={() => setIsOpenMenu(false)}
                      className="lines-button x toggle-active"
                    >
                      <span className="lines" />
                    </a>
                  </S.CloseMenu>
                )}

                <nav>
                  <ul>
                    {menuItems.map((item, index) => (
                      <li
                        key={index}
                        className={`menu-item ${
                          location.pathname === item.path
                            ? "menu-item-active"
                            : ""
                        }`}
                      >
                        <Link to={item.path}>{item.label}</Link>
                      </li>
                    ))}
                    {width <= 1024 && (
                      <>
                        {isLogined ? (
                          <li>
                            <Button
                              className="btn btn-danger btn-rounded"
                              style={{
                                color: "#ffffff",
                                fontSize: 14,
                                height: 50,
                                padding: "9px 36px",
                              }}
                              onClick={() => {
                                dispatch(logout());
                                dispatch(openLoading());
                                messageApi.open({
                                  type: "success",
                                  content: "로그아웃 성공했습니다!",
                                  duration: 2,
                                  onClose: () => {
                                    dispatch(closeLoading());
                                  },
                                });
                              }}
                            >
                              로그아웃
                            </Button>
                          </li>
                        ) : (
                          <>
                            <li>
                              <Link
                                to="/login"
                                className="btn btn-v01 btn-rounded"
                                style={{ color: "#ffffff", fontSize: 14 }}
                              >
                                로그인
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/register"
                                className="btn btn-v02 btn-rounded"
                                style={{ color: "#03000A", fontSize: 14 }}
                              >
                                회원가입
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </nav>
              </div>
            </S.BoxMenu>
            {width > 1024 && (
              <div className="grp-btn">
                {isLogined ? (
                  <Button
                    className="btn btn-v01 btn-rounded"
                    style={{ fontSize: 14, height: 40, padding: "9px 36px" }}
                    onClick={() => {
                      dispatch(logout());
                      dispatch(openLoading());
                      messageApi.open({
                        type: "success",
                        content: "로그아웃 성공했습니다!",
                        duration: 2,
                        onClose: () => {
                          dispatch(closeLoading());
                        },
                      });
                    }}
                  >
                    로그아웃
                  </Button>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-v01 btn-rounded"
                      style={{ fontSize: 14, padding: "12px 36px" }}
                    >
                      로그인
                    </Link>
                    <Link
                      to="/register"
                      className="btn btn-v02 btn-rounded"
                      style={{
                        color: "#03000A",
                        fontSize: 14,
                        padding: "12px 36px",
                      }}
                    >
                      회원가입
                    </Link>
                  </>
                )}
              </div>
            )}
            {/*end: Navigation*/}
          </div>
        </div>
      </header>
    </S.Wrapper>
  );
}

export default Header;
