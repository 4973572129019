import { Button, Modal, ModalProps } from "antd";
import * as S from "./style";

interface LockBuySellModalProps extends ModalProps {
  handleOk: () => void;
}

const LockBuySellModal: React.FC<LockBuySellModalProps> = ({
  handleOk,
  ...props
}) => {
  return (
    <Modal
      {...props}
      centered
      width={500}
      title={
        <S.ModalTitle className="modal-title">
          신청이 금지되었습니다.
        </S.ModalTitle>
      }
      style={{
        padding: "32px 28px 20px 28px",
      }}
      className="cs-common-modal"
      footer={[
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleOk}
            className="btn btn-v01 btn-rounded no-padding"
            style={{ width: 190, height: 50 }}
          >
            확인
          </Button>
        </div>,
      ]}
    />
  );
};

export default LockBuySellModal;
