import { getVerifyPhoneHistoryApi } from "api/my-page";
import { useEffect, useState } from "react";
import * as S from "./style";
import dayjs from "dayjs";

const VerifyHistory: React.FC = () => {
  const [verifyHistory, setVerifyHistory] = useState<any>([]);

  const getVerifyPhoneHistory = async () => {
    const { data, error }: any = await getVerifyPhoneHistoryApi();
    if (error) {
      console.log(error);
      return;
    }
    setVerifyHistory(data);
  };

  useEffect(() => {
    getVerifyPhoneHistory();
  }, []);

  return (
    <S.VerifyHistory>
      <div>
        <span>SMS인증 상태: </span>
        <span>완료</span>
      </div>
      {verifyHistory?.code && (
        <div>
          <span>인증한 번호: </span>
          <span>{verifyHistory?.code}</span>
        </div>
      )}
      {verifyHistory?.create_time && (
        <div>
          <span>인증한 날짜 및 시간: </span>
          <span>
            {/* {dayjs(verifyHistory.create_time).format("YYYY-MM-DD HH:mm:ss")} */}
            {verifyHistory?.create_time
              ?.replace("T", " ")
              ?.replace(".000Z", "")}
          </span>
        </div>
      )}
    </S.VerifyHistory>
  );
};

export default VerifyHistory;
