export enum ApiUrl {
  REGISTER = "/api/v1/user/register",
  LOGIN = "/api/v1/user/login",
  CHECK_UID = "/api/v1/user/check-uid",
  USER_INFO = "/api/v1/user/info",
  GET_ADDRESS_SELL_COIN = "/api/v1/wallet-solution/address-sell-coin",
  BANK_DEFAULT = "/api/v1/bank/bank-default",
  CREATE_BUY_COIN = "/api/v1/buy-coin/create-buy-coin",
  CREATE_SELL_COIN = "/api/v1/sell-coin/create-sell-coin",
  LIST_BUY_COIN = "/api/v1/buy-coin/list-buy-coin-of-user",
  LIST_SELL_COIN = "/api/v1/sell-coin/list-sell-coin-of-user",
  GET_RATE_KRW_USDT = "/api/v1/wallet-solution/rate-krw-usdt",
  GET_BUY_COIN = "/api/v1/buy-coin/get-buy-coin",

  SEND_CODE_VERIFY = "/api/v1/wallet-solution/rate-krw-usdt",
  VERIFY_PHONE_NUMBER = "/api/v1/user/verify-phone-number",
  SEND_VERIFY_PHONE_NUMBER = "/api/v1/user/send-code-verify-phone-number",

  VERIFY_PHONE_HISTORY = "/api/v1/user/verify-phone-history",
  DOWNLOAD_EXCEL_USER_HISTORY = "/api/v1/user/download-excel-user-history",
}
