import styled from "styled-components";

export const ModalContent = styled.div`
  padding: 0 4px;
  p {
    margin-bottom: 10px;
    line-height: 28px;
    font-size: 16px;
  }
`;

export const ModalTitle = styled.p`
  text-align: center;
  padding-top: 20px;
`;

export const ModalSubTitle = styled.p`
  text-align: center;
  font-size: 16px;
`;

export const PageWrapper = styled.div`
  background-color: #03000a;
  position: relative;
  .home-txt {
    position: absolute;
    top: 195px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .big-txt {
    color: #ffffff;
    font-size: 80px;
    font-family: "Montserrat-Medium";
    line-height: 100px;
    margin-bottom: 24px;
  }
  .small-txt {
    color: #b6b3bd;
    font-size: 19.22px;
    font-family: "Montserrat-Regular";
    line-height: 36px;
    max-width: 620px;
    margin: auto;
  }
  .banner-home {
    height: 1080px;
    object-fit: cover;
    width: 100%;
  }
  @media (max-width: 991px) {
    .big-txt {
      color: #ffffff;
      font-size: 30px;
      font-family: "Montserrat-Medium";
      line-height: 36px;
      margin-bottom: 24px;
    }
    .small-txt {
      color: #b6b3bd;
      font-size: 19.22px;
      font-family: "Montserrat-Regular";
      line-height: 36px;
      max-width: 620px;
      margin: auto;
    }
  }
`;
