import React, { useEffect, useState } from "react";
import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import { useHistory } from "react-router-dom";
import { userInfoApi } from "api/user";
import {
  openApproveWarning,
  openLockBuySell,
} from "features/popupManage/popupManageSlice";
import { useDispatch } from "react-redux";

function Purchase() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const resp: any = await userInfoApi();
      if (resp?.result) {
        if (resp?.data?.approve === 0) {
          dispatch(openApproveWarning());
        } else {
          if (resp?.data?.lock_buy_coin === 1) {
            dispatch(openLockBuySell());
          }
        }
      }
    } catch (error) {
      console.log("User Info error", error);
    }
  };

  const gotoPurchaseForm = () => {
    if (isChecked) {
      history.push("/purchase-form");
    }
  };

  return (
    <React.Fragment>
      <S.Wrapper>
        <div className="container sub-page">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-text heading-section">
                <h2>간편구매</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="sub-tit">
                <p className="lead">
                  사기 또는 보이스피싱이 의심되는 경우에 한해서 코인전송이
                  72시간 지연전송됨을 알려드립니다
                </p>
              </div>
              <div className="agree_cont">
                <ul className="dot_list">
                  <li style={{ color: "#FF3C00" }}>
                    구매신청 접수 후 일정시간 이내에 입금 확인이 되지 않을 시
                    신청건은 취소 됩니다.
                  </li>

                  <li>
                    대리신청 또는 대리입금, 부정거래가 의심될 경우 진행하실 수
                    없으며 취소되거나
                    본인확인자료(신분증,통장사본,거래내역,이체확인증등)를 요구할
                    수 있습니다.
                  </li>

                  <li>
                    {" "}
                    대행 결제금액은 계좌이체,CD이체,무통장입금 등의 현금결제만
                    가능합니다.(카드,Pay등 불가)
                  </li>

                  <li>
                    {" "}
                    신청자명과 입금자명은 같아야 하며, 이체된 계좌의 명의인도
                    동일해야 합니다. (불일치시 거래 불가)
                  </li>

                  <li>
                    {" "}
                    입금자명과 상대계좌 명의인이 다르거나 대행금액이 클 경우에는
                    본인확인자료(신분증,통장사본,거래내역,이체확인증) 을 요청 할
                    수 있습니다.
                  </li>

                  <li>
                    {" "}
                    법인계좌, 법인명의 절대 신청 받지 않고 입금 받지않습니다.
                    참고바랍니다.
                  </li>

                  <li>
                    {" "}
                    그외에도 부정거래가 의심될 경우 취소처리하거나 몇가지 사항을
                    확인 후 진행을 시작할 수 있습니다.
                  </li>

                  <li>
                    {" "}
                    대행신청시 잘못된 정보입력, 약관과 설명을 동의하고 신청하여
                    이미 코인전송이 접수된 대행의뢰건에 대해서는 전적으로 의뢰자
                    본인의 책임임을 명확히 안내드립니다.
                  </li>

                  <li>
                    {" "}
                    코인전송이 완료된 신청건에 대해서는 취소 및 환불이 불가한 점
                    참고 부탁드립니다.
                  </li>

                  <li>
                    {" "}
                    구매 및 판매 신청내역 확인은 마이페이지 에서 확인하실 수
                    있습니다.[코인의 전송내역 확인은 신청내역에서 트랜잭션 ID
                    조회를 통해 확인 가능합니다.]
                  </li>

                  <li>
                    {" "}
                    코인전송접수 후 전송완료(트랜잭션승인)까지의 소요시간은
                    블록체인 네트워크 상황에 따라 달라지므로 저희측이 보장해드릴
                    수는 없습니다.
                  </li>

                  <li>
                    {" "}
                    범죄우려 (불법 영상,마약 류,약물 등)가 있는 거래는 시도하지
                    마세요.
                  </li>

                  <li>
                    {" "}
                    약물이나 밀수품등을 구매하시려는 경우 대부분 사기입니다.
                    주의하시기 바랍니다.
                  </li>

                  <li>
                    {" "}
                    중고물품 거래 및 대출진행 수수료를 코인으로 보내라는 경우는
                    100% 사기입니다.
                  </li>

                  <li>
                    {" "}
                    코인으로의 지불&거래는 전송을 접수하는 순간 취소,수정이
                    불가능하며 지갑소유주의 정보를 알수없다는 문제 때문에 매우
                    위험성이 높은 지불&거래방식의 한 예입니다.
                  </li>

                  <li>
                    {" "}
                    코인전송이 염려스럽거나 수상한 사람이 저희사이트에서 대행을
                    신청하라고 요구하는 경우 신중하게 생각하시고 진행 하시기
                    바랍니다.
                  </li>

                  <li>
                    {" "}
                    이러한 위험성을 인지하고 가상화폐(암호화화폐)의
                    구매전송대행을 이용하시는 모든 책임은 당사자 본인에게 있음을
                    안내 드립니다. 또한, 귀사는 책임을 일절 지지 않습니다.
                  </li>
                </ul>
              </div>
              <div className="form-group mb-1 mt-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="reminders"
                    id="reminders"
                    className="custom-control-input"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    required
                  />
                  <label className="custom-control-label" htmlFor="reminders">
                    상기 내용을 확인했습니다.
                  </label>
                </div>
                <div className="t-a-c">
                  <button
                    onClick={gotoPurchaseForm}
                    type="button"
                    className="btn btn-v01 btn-rounded go_purchase"
                    disabled={!isChecked}
                    style={{
                      height: 65,
                      width: 315,
                      fontSize: 18,
                      padding: "27px 18px",
                      marginTop: 48,
                    }}
                  >
                    구매신청
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Purchase;
