import styled from "styled-components";
import CommonBg from "assets/images/common_bg.png";

export const Wrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  background-image: url(${CommonBg});
  /* background-size: cover; */
  background-position: center center;
  z-index: 1;
  .container {
    max-width: 1460px;
    margin-right: auto;
    padding: 0 30px;
  }
  .banner-bg {
    height: 1360px;
    width: 100%;
    object-fit: cover;
  }
  .page-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
  }
  .page-container {
    max-width: 550px;
    margin: 0 auto;
  }
  .heading-text {
    margin: 80px 0 40px;
  }
`;

export const Content = styled.div``;

export const Detail = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const Label = styled.div`
  width: 200px;
  height: 50px;
  padding: 0 16px;
  margin-right: 20px;
  border-radius: 6px;
  background: rgb(246, 246, 246);
  display: inline-flex;
  align-items: center;
  font-weight: 400;
`;

export const Info = styled.div`
  display: inline-flex;
  align-items: center;
  height: 50px;
  border-radius: 6px;
  background: rgb(246, 246, 246, 0.7);
  width: 100%;
  padding: 0 16px;
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;
