import { createGlobalStyle } from "styled-components";
import {
  MontserratMedium,
  MontserratRegular,
  MontserratSemiBold,
} from "assets/fonts/fonts";

const GlobalStyles = createGlobalStyle`
  @font-face {
      font-family: 'Montserrat-Regular';
      src: local('Montserrat-Regular'), url(${MontserratRegular}) format('truetype');
  }

  @font-face {
      font-family: 'Montserrat-Medium';
      src: local('Montserrat-Medium'), url(${MontserratMedium}) format('truetype');
  }

  @font-face {
      font-family: 'Montserrat-SemiBold';
      src: local('Montserrat-SemiBold'), url(${MontserratSemiBold}) format('truetype');
  }

  body{
  font-family: 'Montserrat-Medium' !important;
  font-weight: 500;
  }

  #root {
    overflow: hidden;
  }
  .require {
    .form-control {
      border-color: #CE041C;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .custom-form-antd {
    input{
      font-family: 'Montserrat-Regular';
      font-weight: 400;
    }
    .ant-form-item .ant-form-item-label >label {
      width: 200px;
      margin-right: 20px;
      border-radius: 6px;
      font-size: 13.88px;
      color: #ffffff;
      font-family: 'Montserrat-Regular';
      font-weight: 400;
      background: none;
    }
    .ant-input-number{
      background: rgba(3, 0, 10, 0.50);
      border: 1px rgba(182, 179, 189, 0.15) solid;
      border-radius:6px !important;
    }
    .ant-input-number.ant-input-number-disabled .ant-input-number-input{
      background: none;
      border: none;
    }
    .ant-input-number-group-addon, .ant-input-number-disabled .ant-input-number-group-addon{
      color: #ffffff !important;
      background: transparent !important;
      border: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: 'Montserrat-Regular';
      font-weight: 400;
    }
    .ant-input[disabled]:hover{
      border: 1px rgba(182, 179, 189, 0.15) solid;
    }
    .ant-input, .ant-input-affix-wrapper, .ant-input-number-input, .ant-input-number-input[disabled], .ant-input-disabled{
      background: rgba(3, 0, 10, 0.50);
      color: #ffffff;
      box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.07) inset;
      border: 1px rgba(182, 179, 189, 0.15) solid;
      font-size: 14px !important;
    }
    .ant-input[disabled]{
      color: #ffffff;
    }
    .ant-input-affix-wrapper .ant-input{
      background: transparent;
      border: none;
      box-shadow: none;
    }
    .ant-input-number-input-wrap, .ant-input-number-input{
      background: transparent;
      border: none;
      box-shadow: none;
    }
    .ant-input-suffix svg path{
      fill: #ffffff;
    }
  }
  .ant-input-number-input{
    min-height: 48px !important;
  }
  .ant-input-number-disabled .ant-input-number-input, .ant-input[disabled]{
    color: #000000;
  }
  @media (min-width: 1500px) {
  .container-bs{
    max-width: 1400px;
  }
  }
  .table-text{
    font-size: 14px;
    margin: 0;
  }
  .table-header-text{
    font-size: 14px;
    text-align: center;
    margin: 0;
  }
  .text-center{
    text-align: center;
  }
  .ant-pagination-item-link{
    line-height: 100%;
  }
  .usdt-line{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    font-family: 'Montserrat-SemiBold';
  }
  .count-down-mess{
    text-align: right;
    p,span{
      color: red;
      font-size: 14px;
      margin: 0;
    }
  }

  .cs-modal{
    color: #ffffff;
    .ant-modal-content{
      padding: 1.75rem 1.25rem;
      background: rgba(52.42, 58.81, 78.63, 0.75);
      box-shadow: 0px 0px 20px 8px rgba(125, 108, 255, 0.21);
      border-radius: 12px;
      border: 1px #7d6cff solid;
      backdrop-filter: blur(25px);
    }
    .ant-modal-header{
      background-color: transparent;
      padding-bottom: 20px;
      border-bottom: 1px rgb(182 179 189 / 0.5) solid;
      margin-bottom: 0;
    }
    .ant-modal-body{
      padding-top: 20px;
    }
    .ant-modal-footer{
      margin: 0;
    }
    .modal-title{
      font-size: 24px;
      padding: 0;
      margin: 0;
      font-family: 'Montserrat-SemiBold';
      font-weight: 600;
    }
    .modal-txt-line{
      display: flex;
      align-items: baseline;
      font-size: 18px;
      font-family: 'Montserrat-Medium';
      line-height:28px;
    }
  }
  .cs-common-modal{
    color: #ffffff;
    .ant-modal-close{
      top: 25px;
    }
    .ant-modal-close-x{
      svg path{
        fill: #ffffff;
      }
    }
    .ant-modal-content{
      padding: 28px 20px;
      background: rgba(52.42, 58.81, 78.63, 0.75);
      box-shadow: 0px 0px 20px 8px rgba(125, 108, 255, 0.21);
      border-radius: 12px;
      border: 1px #7d6cff solid;
      backdrop-filter: blur(25px);
    }
    .ant-modal-header{
      background-color: transparent;
      padding-bottom: 20px;
      border-bottom: 1px rgb(182 179 189 / 0.5) solid;
      margin-bottom: 0;
    }
    .ant-modal-body{
      padding-top: 20px;
    }
    .ant-modal-footer{
      margin: 0;
    }
    .modal-title{
      font-size: 24px;
      padding: 0;
      margin: 0;
      font-family: 'Montserrat-SemiBold';
      font-weight: 600;
      text-align: left;
    }
    .modal-txt-line{
      display: flex;
      align-items: baseline;
      font-size: 18px;
      font-family: 'Montserrat-Medium';
      line-height:28px;
    }
  }
  .cms-content{
    .head-line{
      position: relative;
      padding-left: 20px;
      > strong > span{
        color: #7D6CFF; 
        font-size: 24px !important; 
        font-family: 'Montserrat-SemiBold';
        font-weight: 600;
      }
      &.white{
        > strong > span{
        color: #ffffff; 
        font-size: 18px !important; 
        font-family: 'Montserrat-Medium';
        font-weight: 500;
      }
      }
      &:before{
        content: "";
        width: 12px; 
        height: 12px; 
        transform: rotate(45deg); 
        transform-origin: 0 0; 
        background: #7D6CFF; 
        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.25) inset; 
        border-radius: 2px;
        position: absolute;
        top: 5px;
        left: 6px;
      }
    }
    p {
      > strong > span {
        font-family: "Montserrat-SemiBold";
        font-weight: 600;
        font-size: 18px !important;
        line-height: 28px;
      }
      > span {
        color: #b6b3bd;
        font-size: 18px !important;
        line-height: 28px;
      }
    }
    td{
      border: 1px #B6B3BD  solid !important;
      padding: 10px 20px;
      p{
        margin: 0
      }
    }
  }
  .agree_cont{
    padding: 28px 24px;
    background: rgba(255, 255, 255, 0.07); 
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.07) inset; 
    border-radius: 24px; 
    border: 1px #B6B3BD solid;
    margin-top: 40px;
    ul{
      margin: 0;
    }
    li{
      font-size: 18px;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 16px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .cs-tab{
    .ant-tabs-nav{
      &:before{
        border-bottom: 1px solid #343B4F ;
      }
    }
    .ant-tabs-tab{
      margin: 0 !important;
    }
    .ant-tabs-tab-btn{
      color: #ffffff;
      opacity: 0.5;
      font-size: 14px; 
      font-family: 'Montserrat-Regular';
      font-weight: 400; 
      line-height: 24px; 
      word-wrap: break-word;
      padding: 0 16px;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
      color: #7D6CFF !important;
      opacity: 1;
    }
    .ant-tabs-ink-bar{
      background: #7D6CFF;
    }
  }

  .cs-table{
    .ant-table{
      background: none;
    }
    .ant-table-thead >tr>th{
      background: #343B4F;
      font-size: 14px; 
      font-family: 'Montserrat-Semibold';
      font-weight: 600; 
      color: #ffffff;
      border: none;
      &:before{
        display: none;
      }
    }
    .ant-table-tbody >tr{
      &:hover{
        >td{
          opacity: 0.7;
          background: #0A1330 !important;
        }
      }
      >td{
        background: #0A1330;
        font-size: 14px; 
        font-family: 'Montserrat-Medium';
        font-weight: 500; 
        border: none;
      }
      &:nth-child(odd){
        $:hover{
          >td{
            opacity: 0.7;
            background: rgba(255, 255, 255, 0.07) !important;
          }
        }
        >td{
          background: rgba(255, 255, 255, 0.07);
        }
      }
    } 

    .ant-pagination{
      background: rgba(255, 255, 255, 0.07);
      margin: 0 !important;
      padding: 28px 0;
      border-radius: 0 0 8px 8px;
      a{
        color: #B6B3BD;
        font-size: 14px;
        font-family: 'Montserrat-Regular';
        font-weight: 400; 
      }
      .ant-pagination-item-active{
        background: #7D6CFF;
        border: transparent;
        a{
          font-family: 'Montserrat-Semibold';
          font-weight: 600; 
          color: #ffffff !important;
        }
      }
      .ant-pagination-prev, .ant-pagination-next{
        button{
          border: 1px #D9E1FA solid;
          border-radius: 8px;
        }
        svg path{
          fill: #B6B3BD;
        }
      }
    }
  }

  .dot_list{
    list-style: none;
    li{
      position: relative;
      padding-left: 25px;
      &:before{
        content: "";
        width: 12px; 
        height: 12px; 
        transform: rotate(45deg); 
        transform-origin: 0 0; 
        background: #7D6CFF; 
        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.25) inset; 
        border-radius: 2px;
        position: absolute;
        top: 3px;
        left: 6px;
      }
    }
  }

  .sub-wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 60px ;
  }

  .sub-title{
    display: flex;
    flex-direction: column;
    p{
      position: relative;
      margin-bottom: 8px;
      padding-left: 25px;
      font-family: 'Montserrat-Medium';
      font-weight: 500 !important; 
      &:before{
        content: "";
        width: 12px; 
        height: 12px; 
        transform: rotate(45deg); 
        transform-origin: 0 0; 
        background: #7D6CFF; 
        box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.25) inset; 
        border-radius: 2px;
        position: absolute;
        top: 5px;
        left: 6px;
      }
    }
  }
`;

export default GlobalStyles;
