import styled from "styled-components";
import CommonBg from "assets/images/common_bg.png";

export const Wrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 233px;
  position: relative;
  background-image: url(${CommonBg});
  /* background-size: cover; */
  background-position: center center;
  z-index: 1;
  min-height: 100vh;
  .container {
    max-width: 1460px;
    margin-right: auto;
    padding: 0 30px;
  }
  .banner-bg {
    height: 900px;
    width: 100%;
    object-fit: cover;
  }
  .page-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
  }
  .page-container {
    max-width: 550px;
    margin: 0 auto;
  }
  .heading-text {
    margin: 80px 0 10px;
  }
  .sub-tit .lead {
    font-size: 20px;
    line-height: 28px;
    color: #b6b3bd;
    text-align: center;
  }
`;

export const ModalTitle = styled.p`
  text-align: center;
  padding-top: 20px;
`;
