import styled from "styled-components";
import CommonBg from "assets/images/common_bg.png";

export const Wrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 233px;
  position: relative;
  background-image: url(${CommonBg});
  /* background-size: cover; */
  background-position: center center;
  z-index: 1;
  min-height: 100vh;
  .container {
    max-width: 1460px;
    margin-right: auto;
    padding: 0 30px;
  }
  .banner-bg {
    height: 900px;
    width: 100%;
    object-fit: cover;
  }
  .page-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
  }
  .page-container {
    max-width: 550px;
    margin: 0 auto;
  }
  .heading-text {
    margin: 80px 0 10px;
  }
  .sub-tit .lead {
    font-size: 20px;
    line-height: 28px;
    color: #b6b3bd;
    text-align: center;
  }
  .modal-title {
    text-align: center;
  }
  .txid {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const VerifyHistory = styled.div`
  > div {
    display: flex;
    column-gap: 6px;
    margin-bottom: 12px;
    > span {
      display: block;
      font-size: 16px;
      color: #ffffff;
    }
  }
`;

export const DownloadButton = styled.button`
  height: 40px;
  padding: 0 20px;
  border: 1px #ffffff solid;
  background-color: transparent;
  font-size: 14px;
  font-family: "Montserrat-Semibold";
  font-weight: 600;
  color: #fff;
  border-radius: 100px;
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 767px) {
    top: -60px;
  }
  &:focus {
    border: 0;
    outline: none;
  }
`;
