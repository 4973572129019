import React, { useState, FormEvent, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import * as S from "./style";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import api from "api";

interface FormValuesType {
  fullName: string;
  passwd: string;
  contact: string;
  email: string;
  title: string;
  content: string;
}

interface PayloadType {
  name: string;
  password: string;
  contact: string;
  email: string;
  title: string;
  content: string;
}

function Inquire() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [formValues, setFormValues] = useState<FormValuesType>({
    fullName: "",
    passwd: "",
    contact: "",
    email: "",
    title: "",
    content: "",
  });

  const [errors, setErrors] = useState<Partial<FormValuesType>>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    // Gửi dữ liệu form đi
    console.log("form data:", formValues);

    const payload: PayloadType = {
      name: values.fullName,
      password: values.passwd,
      contact: values.contact,
      email: values.email,
      title: values.title,
      content: values.content,
    };

    try {
      const response = await api.post(
        "/api/v1/sell-coin/create-inquire",
        payload
      );
      if (response) {
        alert("접수되었습니다.");
        setFormValues({
          fullName: "",
          passwd: "",
          contact: "",
          email: "",
          title: "",
          content: "",
        });
      }
    } catch (error) {
      alert("오류.");
      console.log(error);
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "inputMoney" && Number(value) <= 0) {
      return;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value) {
        delete updatedErrors[name as keyof FormValuesType];
      } else {
        updatedErrors[name as keyof FormValuesType] = "require";
      }
      return updatedErrors;
    });
  };

  const goToPolicy = (event: any) => {
    event.preventDefault();
    const path = event.target.getAttribute("href");
    const url = window.location.origin + path;
    window.open(url, "_blank");
  };

  return (
    <React.Fragment>
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>고객지원</h2>
          </div>
          <S.Content>
            <div className="custom-form-antd">
              <Form
                form={form}
                name="control-hooks"
                onFinish={handleSubmit}
                layout="vertical"
              >
                <div className="form-wrapper">
                  <Form.Item style={{ margin: 0 }}>
                    <Form.Item
                      name="fullName"
                      label="이름"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input style={{ height: 50, width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      name="passwd"
                      label="비밀번호"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        marginLeft: 16,
                      }}
                    >
                      <Input style={{ height: 50, width: "100%" }} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Form.Item
                      name="contact"
                      label="연락처"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input style={{ height: 50, width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="이메일"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        marginLeft: 16,
                      }}
                    >
                      <Input style={{ height: 50, width: "100%" }} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="title"
                    label="제목"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <Input style={{ height: 50, width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    label="내용"
                    name="content"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <Input style={{ height: 50, width: "100%" }} />
                  </Form.Item>
                </div>
                <div
                  className="custom-control custom-checkbox"
                  style={{ marginTop: 20 }}
                >
                  <input
                    type="checkbox"
                    name="reminders"
                    id="reminders"
                    className="custom-control-input"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="reminders"
                    style={{
                      fontSize: 16,
                      lineHeight: "25px",
                      backgroundColor: "transparent",
                    }}
                  >
                    개인정보수집 이용 동의
                  </label>
                  <span className="bottom-agree">
                    <a
                      onClick={goToPolicy}
                      href="/privacy-polic"
                      style={{
                        fontSize: 16,
                        lineHeight: "25px",
                        color: "#6091FF",
                        textDecoration: "underline",
                      }}
                    >
                      [자세히보기]
                    </a>
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    size="large"
                    className="btn btn-v01 btn-rounded"
                    htmlType="submit"
                    style={{
                      height: 65,
                      width: 315,
                      fontSize: 18,
                      padding: "27px 18px",
                      marginTop: 48,
                    }}
                    disabled={!isChecked}
                  >
                    완료
                  </Button>
                </div>
              </Form>
            </div>
          </S.Content>
          {/* <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="token" defaultValue="" />
                <div className={`form-group row ${errors.fullName}`}>
                  <label
                    htmlFor="example-text-input"
                    className="col-3 col-form-label"
                  >
                    이름<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="fullName"
                      type="text"
                      id="example-text-input"
                      value={formValues.fullName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.passwd}`}>
                  <label
                    htmlFor="example-password-input"
                    className="col-3 col-form-label"
                  >
                    비밀번호<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="passwd"
                      type="password"
                      id="example-password-input"
                      value={formValues.passwd}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.contact}`}>
                  <label
                    htmlFor="example-tel-input"
                    className="col-3 col-form-label"
                  >
                    연락처<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="contact"
                      type="tel"
                      id="example-tel-input"
                      value={formValues.contact}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.email}`}>
                  <label
                    htmlFor="example-email-input"
                    className="col-3 col-form-label"
                  >
                    이메일<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      id="example-email-input"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.title}`}>
                  <label
                    htmlFor="example-search-input"
                    className="col-3 col-form-label"
                  >
                    제목<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      name="title"
                      // required
                      className="form-control required"
                      value={formValues.title}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.content}`}>
                  <label
                    htmlFor="example-url-input"
                    className="col-3 col-form-label"
                  >
                    내용<em>*</em>
                  </label>
                  <div className="col-9">
                    <textarea
                      name="content"
                      // required
                      rows={5}
                      className="form-control required"
                      value={formValues.content}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`che-inq mb-1 mt-4`}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="reminders"
                      id="reminders"
                      className="custom-control-input"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <label className="custom-control-label" htmlFor="reminders">
                      개인정보수집 이용 동의
                    </label>
                    <span className="bottom-agree">
                      <a onClick={goToPolicy} href="/privacy-polic">
                        [자세히보기]
                      </a>
                    </span>
                  </div>
                  <div className="t-a-c">
                    <button
                      type="submit"
                      className="btn btn-danger"
                      disabled={!isChecked}
                    >
                      완료
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Inquire;
