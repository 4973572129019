import { apiConfig } from "api";
import { ApiUrl } from "constant/endpoint";

export const getVerifyPhoneHistoryApi = async () => {
  return apiConfig.request({
    url: ApiUrl.VERIFY_PHONE_HISTORY,
    method: "GET",
    tokenRequired: true,
  });
};

export const downloadExcelUserHistoryApi = async () => {
  return apiConfig.request({
    url: ApiUrl.DOWNLOAD_EXCEL_USER_HISTORY,
    method: "GET",
    tokenRequired: true,
    responseType: "blob",
  });
};
