import { Tabs, TabsProps } from "antd";
import ArrowDown from "assets/images/arrowDown.svg";
import React, { useState } from "react";
import DataBuy from "./DataBuy";
import DataSell from "./DataSell";
import * as S from "./style";
import fileDownload from "js-file-download";
import { downloadExcelUserHistoryApi } from "api/my-page";
import VerifyHistory from "./VerifyHistory";

function Mypage() {
  const [tabKey, setTabKey] = useState<string>("1");

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "코인구매 신청내역",
      children: tabKey === "1" && <DataBuy />,
    },
    {
      key: "2",
      label: "코인 판매 신청내역",
      children: tabKey === "2" && <DataSell />,
    },
    {
      key: "3",
      label: "인증내역",
      children: tabKey === "3" && <VerifyHistory />,
    },
  ];
  const onChange = (k: string) => {
    setTabKey(k);
  };

  const handleDownload = async () => {
    try {
      const resp: any = await downloadExcelUserHistoryApi();
      if (resp) {
        fileDownload(resp, "user-info.xlsx");
      }
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <React.Fragment>
      <S.Wrapper>
        <div className="container container-bs sub-page inquire">
          <div className="heading-text heading-section">
            <h2>마이페이지</h2>
          </div>
          <S.Content>
            <Tabs
              defaultActiveKey={tabKey}
              items={items}
              onChange={onChange}
              className="cs-tab"
            />
            <S.DownloadButton onClick={handleDownload}>
              정보 다운로드 <img src={ArrowDown} alt="" />
            </S.DownloadButton>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Mypage;
