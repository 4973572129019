import { animateScroll as scroll } from "react-scroll";
import homeBg from "../../assets/images/home_bg.png";
import IconChevronRight from "../../assets/images/iconChevronRight.svg";
import * as S from "./style";
import { useEffect, useState } from "react";
import { Button, Modal } from "antd";

function Homepage() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (window.location.hash === "#menu1") {
      const element = document.getElementById("menu1");
      if (element) {
        const position = element.offsetTop;
        scroll.scrollTo(position, {
          spy: true,
          smooth: true,
          offset: -50,
          duration: 500,
        });
      }
    } else if (window.location.hash === "#menu2") {
      const element = document.getElementById("menu2");
      if (element) {
        const position = element.offsetTop;
        scroll.scrollTo(position, {
          spy: true,
          smooth: true,
          offset: -50,
          duration: 500,
        });
      }
    }
  }, []);

  return (
    <>
      {/* Inspiro Slider */}
      <S.PageWrapper>
        <div className="home-txt">
          <div className="big-txt">
            Your Trusted
            <br />
            Purchasing Agency
          </div>
          <div className="small-txt">
            Let's collaborate to shape a future where opportunities are
            limitless and possibilities are decentralized.
          </div>
        </div>
        <img className="banner-home" src={homeBg} alt="bg" />
      </S.PageWrapper>

      <Modal
        open={isModalOpen}
        centered
        width={500}
        title={<S.ModalTitle className="modal-title">필 독</S.ModalTitle>}
        style={{
          padding: "32px 28px 20px 28px",
        }}
        closable={false}
        footer={false}
        className="cs-modal"
      >
        <S.ModalContent>
          <p className="modal-txt-line">
            <img
              src={IconChevronRight}
              style={{ marginRight: 8 }}
              alt="chevron"
            />
            영업시간 연중무휴 24시
          </p>
          <p className="modal-txt-line">
            <img
              src={IconChevronRight}
              style={{ marginRight: 8 }}
              alt="chevron"
            />
            최근 코인거래 사기가 많아 코인거래 구매 및 판매시 신중히 확인 후
            진행 부탁드립니다. <br />
            (대행신청 후 사고 발생시 당사는 도움을 드릴수 없는점 참고
            부탁드립니다.)
          </p>
          <p className="modal-txt-line">
            <img
              src={IconChevronRight}
              style={{ marginRight: 8 }}
              alt="chevron"
            />
            대행 신청시 코인전송이 진행된 건에 대해서는 취소 및 환불이
            불가합니다.
          </p>
          <p className="modal-txt-line">
            <img
              src={IconChevronRight}
              style={{ marginRight: 8 }}
              alt="chevron"
            />
            당사는 불법자금유통,자금세탁,금융사기등을 원천차단 노력하고있습니다.
            <br />
            (위에 해당되는 거래 의심시 당사는 회원 본인에게
            [신분증,통장사본,거래내역, 이체확인증] 을 요구 할 수 있습니다.)
          </p>
          <p className="modal-txt-line">
            <img
              src={IconChevronRight}
              style={{ marginRight: 8 }}
              alt="chevron"
            />
            잘못된 정보입력 및 대행 신청전 유의사항 상기 내용 확인의 동의 후
            진행되어 완료된 신청건에 대하여 발생한 문제는 전적으로 회원 본인의
            책임임을 명확하게 안내드립니다.
          </p>
          <p className="modal-txt-line">
            <img
              src={IconChevronRight}
              style={{ marginRight: 8 }}
              alt="chevron"
            />
            대행 신청시 유의사항 필독 후 신청 부탁 드립니다.
          </p>
          <Button
            size="large"
            className="btn btn-v01 btn-rounded"
            style={{
              width: "100%",
              margin: "10px 0",
              height: 50,
              fontSize: 18,
            }}
            onClick={() => setIsModalOpen(false)}
          >
            닫기
          </Button>
        </S.ModalContent>
      </Modal>
    </>
  );
}

export default Homepage;
