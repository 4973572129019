import * as S from "./style";
import loginBg from "../../assets/images/login_bg.png";
import React, { useEffect, useMemo } from "react";
import { Button, Form, Input, message, Space } from "antd";
import { sendCodeVerifyApi, verifyPhoneNumberApi } from "api/register";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SITE_NAME } from "constant/configs";
import { useCountTime } from "customHook/useCountTime";
import { secondToMinute } from "utils/format";
const TIME_TO_SENDEMAIL = 180;
const OTP_SEND_KEY = "otp_send";

function VerifyPhoneNumber() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  const { countTime, handleSetCountTime } = useCountTime(0, OTP_SEND_KEY);
  const { state }: any = useLocation();
  // console.log("state", state);

  useEffect(() => {
    // localStorage.setItem(OTP_SEND_KEY, JSON.stringify(null));
    handleSetCountTime(0);
  }, []);

  const isReadySend = useMemo(() => {
    return countTime <= 0;
  }, [countTime > 0]);

  const handleVerify = async (values: any) => {
    try {
      const resp: any = await verifyPhoneNumberApi({
        id: state,
        phone: values.phone,
        code: values.code,
      });
      // console.log("handleRegister resp", resp);
      if (resp?.result) {
        messageApi.open({
          type: "success",
          content: resp?.message || "성공했습니다.",
          duration: 1,
          onClose: () => {
            history.push("/login");
          },
        });
        return;
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message || "오류입니다",
      });
    } catch (error) {
      console.log("handleRegister error", error);
    }
  };

  const handleSendVerify = async () => {
    // if (isReadySend) {
    //   handleSetCountTime(TIME_TO_SENDEMAIL);
    // }
    try {
      const resp: any = await sendCodeVerifyApi({
        phone: form.getFieldValue("phone"),
      });
      // console.log("handleSendVerify", resp);
      if (resp?.result) {
        handleSetCountTime(TIME_TO_SENDEMAIL);
        messageApi.open({
          type: "success",
          content: resp?.message,
        });
        return;
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message,
      });
    } catch (error) {
      console.log("sendCodeVerifyApi error", error);
    }
  };

  const checkPhoneNumber = () => {
    form
      .validateFields(["phone"])
      .then((values) => {
        // If validation is successful, you can proceed with your logic here
        // console.log("Validated values:", values);
        handleSendVerify();
      })
      .catch((info) => {
        // If validation fails, you can handle it here
        console.log("Validation Failed:", info);
      });
  };

  console.log("isReadySend", isReadySend);

  return (
    <React.Fragment>
      {contextHolder}
      <S.Wrapper>
        <img className="banner-bg" src={loginBg} alt="bg" />
        <div className="page-content">
          <div className="page-container">
            <div className="heading-text heading-section">
              <h2>회원가입</h2>
            </div>
            <S.Content>
              <div className="custom-form-antd">
                <Form
                  form={form}
                  name="control-hooks"
                  onFinish={handleVerify}
                  layout="vertical"
                >
                  <Form.Item
                    name="phone"
                    label="전화번호"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject();
                          }
                          if (isNaN(value)) {
                            return Promise.reject("숫자를 입력해주세요.");
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Space.Compact
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Input style={{ height: 50, borderRadius: 6 }} />
                      <Button
                        style={{
                          height: 40,
                          margin: 0,
                          position: "absolute",
                          top: 5,
                          right: 5,
                          fontSize: 14,
                          padding: "0px 22px",
                          zIndex: 9,
                        }}
                        className="btn btn-v01"
                        type="primary"
                        onClick={checkPhoneNumber}
                        // disabled={!isReadySend}
                      >
                        {isReadySend ? "전송" : "재전송"}
                      </Button>
                    </Space.Compact>
                  </Form.Item>
                  <Form.Item
                    name="code"
                    label="인증번호"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <Input
                      style={{ height: 50 }}
                      maxLength={30}
                      suffix={
                        !isReadySend && (
                          <div className="count-down-mess">
                            <p>
                              {/* 인증번호 만료시간 */}
                              <span>{secondToMinute(countTime)}</span>
                            </p>
                          </div>
                        )
                      }
                    />
                  </Form.Item>
                  <p
                    style={{ marginTop: 20, fontSize: 14, lineHeight: "25px" }}
                  >
                    {SITE_NAME}의{" "}
                    <Link
                      to="/terms-of-use"
                      style={{ color: "#6091FF", textDecoration: "underline" }}
                    >
                      이용약관
                    </Link>
                    ,
                    <Link
                      to="/privacy-policy"
                      style={{ color: "#6091FF", textDecoration: "underline" }}
                    >
                      개인정보 처리방침
                    </Link>
                    을 확인하시고 내용에 동의하실 경우에만 회원가입을
                    진행해주십시오.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 64,
                    }}
                  >
                    <Button
                      htmlType="submit"
                      size="large"
                      className="btn btn-v01 btn-rounded"
                      style={{ height: 50, width: 315, fontSize: 18 }}
                    >
                      인증
                    </Button>
                  </div>
                </Form>
              </div>
            </S.Content>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default VerifyPhoneNumber;
