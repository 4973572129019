import * as S from "./style";
import UsdtIcon from "assets/images/USDT_icon.svg";
import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import {
  amountExchange,
  MoneyConvert,
  onNumericInputChange,
} from "utils/format";
import { sellApi } from "api/sell";
import { getRateKrwUsdtApi, userInfoApi } from "api/user";
import { openLoading, closeLoading } from "features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  openApproveWarning,
  openLockBuySell,
} from "features/popupManage/popupManageSlice";

function SellForm() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const { state }: any = useLocation();
  const [dataRate, setDataRate] = useState<any>();

  const getUserInfo = async () => {
    try {
      const bankInfoResp: any = await userInfoApi();
      if (bankInfoResp?.result) {
        form.setFieldsValue({ bank_name: bankInfoResp?.data?.bank_name });
        form.setFieldsValue({ account_name: bankInfoResp?.data?.account_name });
        form.setFieldsValue({
          account_number: bankInfoResp?.data?.account_number,
        });
        form.setFieldsValue({
          address_receiving: state,
        });
        if (bankInfoResp?.data?.approve === 0) {
          dispatch(openApproveWarning());
        } else {
          if (bankInfoResp?.data?.lock_sell_coin === 1) {
            dispatch(openLockBuySell());
          }
        }
      }
    } catch (error) {
      console.log("getBankInfo error", error);
    }
  };

  useEffect(() => {
    getUserInfo();
    getDataExchange();
  }, []);

  const getDataExchange = async () => {
    try {
      const resp: any = await getRateKrwUsdtApi();
      if (resp?.result) {
        setDataRate(resp?.data);
      }
    } catch (error) {
      console.log("getDataExchange error", error);
    }
  };

  const handleSell = async (values: any) => {
    dispatch(openLoading());
    try {
      const resp: any = await sellApi({
        total_exchange: values.total_exchange,
        amount: amountExchange(
          dataRate?.rate_sell_coin,
          dataRate?.rate_krw_usdt,
          values.amount,
          "SELL"
        ),
        user_address: values.user_address,
        bank_name: values.bank_name,
        account_number: values.account_number,
        account_name: values.account_name,
        address_receiving: values.address_receiving,
        rate_sell_coin: dataRate?.rate_sell_coin,
        rate_krw_usdt: dataRate?.rate_krw_usdt,
      });
      if (resp?.result) {
        messageApi.open({
          type: "success",
          duration: 2,
          content: resp?.message || "성공했습니다.",
          onClose: () => {
            dispatch(closeLoading());
          },
        });
        form.setFieldsValue({ total_exchange: "" });
        form.setFieldsValue({ amount: "" });
        form.setFieldsValue({ user_address: "" });
        return;
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message || "오류입니다.",
      });
      dispatch(closeLoading());
    } catch (error) {
      console.log("handlePurchase error", error);
      dispatch(closeLoading());
    }
  };

  return (
    <React.Fragment>
      {contextHolder}
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>간편판매 신청서작성</h2>
            <div className="sub-wrapper">
              <div className="sub-title">
                <p>
                  출금 : 1회 출금 한도{" "}
                  {MoneyConvert(
                    Number(dataRate?.limit_sell_amount / 10000) || 0
                  )}
                  만원. 1일 무제한 가능합니다.
                </p>
                <p>1회 출금한도 이상 출금시 한도 내 분할 출금바랍니다.</p>
              </div>
            </div>
          </div>
          <S.Content>
            <div className="custom-form-antd">
              <Form
                form={form}
                name="control-hooks"
                onFinish={handleSell}
                layout="vertical"
              >
                <div className="form-wrapper">
                  <div className="usdt-line">
                    <img src={UsdtIcon} alt="" width="28px" />
                    {MoneyConvert(dataRate?.rate_krw_usdt || 0)} oUSDT
                  </div>
                  <Form.Item
                    name="total_exchange"
                    label="판매금액"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      style={{ height: "100%", width: "100%" }}
                      addonAfter="원"
                      max={(Number(dataRate?.limit_sell_amount) || 0) as number}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) =>
                        parseFloat(value!.replace(/\$\s?|(,*)/g, ""))
                      }
                      onChange={(value) => {
                        const parsed = onNumericInputChange(value);
                        if (parsed) {
                          form.setFieldsValue({ amount: parsed });
                        }
                      }}
                      controls={false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="판매수량"
                    name="amount"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      style={{ height: 50, width: "100%" }}
                      addonAfter="개"
                      disabled
                      formatter={(value) =>
                        `${amountExchange(
                          dataRate?.rate_sell_coin,
                          dataRate?.rate_krw_usdt,
                          value || 0,
                          "SELL"
                        )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      controls={false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="user_address"
                    label="보내는 지갑주소"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <Input style={{ height: 50, width: "100%" }} />
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Form.Item
                      name="bank_name"
                      label="계좌은행"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input style={{ height: 50, width: "100%" }} disabled />
                    </Form.Item>
                    <Form.Item
                      name="account_number"
                      label="계좌번호"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        marginLeft: 16,
                      }}
                    >
                      <Input style={{ height: 50, width: "100%" }} disabled />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="account_name"
                    label="계좌주명"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <Input style={{ height: 50, width: "100%" }} disabled />
                  </Form.Item>
                  <Form.Item
                    name="address_receiving"
                    label="받을 지갑주소"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input style={{ height: 50, width: "100%" }} disabled />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    size="large"
                    className="btn btn-v01 btn-rounded"
                    htmlType="submit"
                    style={{
                      height: 65,
                      width: 315,
                      fontSize: 18,
                      padding: "27px 18px",
                      marginTop: 48,
                    }}
                  >
                    신청
                  </Button>
                </div>
              </Form>
            </div>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default SellForm;
