import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  .banner-bg {
    height: 1585px;
    width: 100%;
    object-fit: cover;
  }
  .page-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
  }
  .page-container {
    max-width: 550px;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  padding: 0 16px;
  max-width: 700px;
  margin: 0 auto;
`;
