import styled from "styled-components";
import CommonBg from "assets/images/common_bg.png";

export const Wrapper = styled.div`
  padding-top: 100px;
  position: relative;
  background-image: url(${CommonBg});
  /* background-size: cover; */
  background-position: center center;
  z-index: 1;
  min-height: 100vh;
  .container {
    max-width: 870px;
    margin-right: auto;
    padding: 0 30px;
  }
  .banner-bg {
    height: 900px;
    width: 100%;
    object-fit: cover;
  }
  .page-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
  }
  .page-container {
    max-width: 550px;
    margin: 0 auto;
  }
  .heading-text {
    margin: 80px 0 40px;
  }
`;

export const Content = styled.div`
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.07) inset;
  border-radius: 24px;
  border: 1px #b6b3bd solid;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  color: white;
  font-size: 13.88px;
  font-family: "Montserrat-Regular";
  font-weight: 400;
  line-height: 27.2px;
  margin-bottom: 8px;
`;

export const Info = styled.div`
  background: rgba(3, 0, 10, 0.35);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.07) inset;
  border-radius: 8px;
  overflow: hidden;
  border: 1px rgba(182, 179, 189, 0.15) solid;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;
